import React from 'react'
import SharingButtons from './sharingButtons'
import './Jumbotron.css'
import ModalAddVideo from './ModalAddVideo'
import Metadata from './Metadata'
import { Link } from 'gatsby'
import { purgeStoredState } from 'redux-persist'

const GenericJumbotron = ({title, description, url}) => {
  let userName = null
  if (typeof window !== "undefined") {
    userName = localStorage.getItem('userName')
  }


  return (
    <div className="jumbotron bg-blue mb-0 rounded-0 d-md-flex">
      <Metadata
          type="post"
          pathname={url}
          title={title}
          description={description}
          poster={null}
      />
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-white">
          <h1 className="helsinki"><strong>{title}</strong></h1>
          <p className="">{description}</p>
          {/* Inspiré par <a className="text-light" rel="nofollow noreferrer noopener external" target="_blank" href="https://www.openculture.com/freemoviesonline">Openculture </a><span> &amp; </span>
          <a className="text-light" rel="nofollow noreferrer noopener external" target="_blank" href="https://archive.org/details/moviesandfilms">The Internet archive</a>.
          <p>
            <span className="text-white">Autres listes: </span>
            <Link className="text-light" to="/collections/vieux-films-gratuits/">Vieux films gratuits</Link><span>, </span>
            <Link className="text-light" to="/collections/documentaires-gratuits/">Documentaires gratuits</Link><span>, </span>
            <Link className="text-light" to="/collections/dessins-animes-gratuits/">Dessins animés gratuits</Link><span>, </span>
            <Link className="text-light" to="/collections/films-horreur-gratuits/">Films d'horreur gratuits</Link><span>, </span>
            <Link className="text-light" to="/collections/mystere-thriller-gratuits/">Thrillers gratuits</Link><span>, </span>
            <Link className="text-light" to="/collections/westerns-gratuits/">Westerns gratuits</Link><span>, </span>
          </p> */}
              <div>
                <Link to="/collections/vieux-films-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Vieux films</Link>
                <Link to="/collections/action-aventure-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Action / Aventure</Link>
                <Link to="/collections/documentaires-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Documentaires</Link>
                <Link to="/collections/dessins-animes-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Dessins animés</Link>
                <Link to="/collections/films-horreur-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Horreur</Link>
                <Link to="/collections/mystere-thriller-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Mystère/Thriller</Link>
                <Link to="/collections/westerns-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Western</Link>
                <Link to="/collections/films-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Films</Link>
                <Link to="/collections/films-gratuits-youtube/" className="btn btn-sm btn-outline-light mr-1 mb-1">Sur YouTube</Link>
              </div>
          <div className="my-3">
            <SharingButtons url={url} title={title} theme="white"/>
          </div>
            <div className="d-flex flex-column flex-lg-row">
                <div>
                { userName &&
                    <ModalAddVideo auth="login">
                    <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1`}>Ajouter un film</button>
                    </ModalAddVideo>
                } 
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
)}

export default GenericJumbotron