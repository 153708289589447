import React from "react"
import { graphql, Link } from "gatsby"
import GenericJumbotron from "./GenericJumbotron"
import Layout from "./Layout"
import { Lazy } from "react-lazy"
import { Disqus } from "gatsby-plugin-disqus"
import { FullWidthAd } from "./AdComponent"
import { GENRES_EN_TO_FR } from "../const"

const VideoList = ({url, title, description, edges, similar}) => {
    const maxWidth = 200
    const seen = new Set();
    const videos = edges.map(i => {
        if (seen.has(i.node.translation_slug)){
            return null
        }
        seen.add(i.node.translation_slug);
        let genres = ( i.node.movie_genres && i.node.movie_genres.length ) &&
        i.node.movie_genres.map(i => {
            let genre = GENRES_EN_TO_FR[`${i}`]
            return `${genre}`
        }).slice(0,3).toString()
        return (
        <Link to={`/t/${i.node.translation_slug}`} key={i.node.translation_slug} className="m-2 shadow rounded-sm underline-not">
            <div style={{width:maxWidth, height:maxWidth*1.43}}>
                <Lazy>
                    <img alt={`${i.node.translation_title} affiche du film`} loading="lazy" src={i.node.translation_poster && i.node.translation_poster.url} width={maxWidth} height={maxWidth*1.43}/>
                </Lazy>
            </div>
            <div className="py-2 px-3" style={{maxWidth: maxWidth}}>
                <div className="">
                    <span className="text-secondary small overflow-hidden"> {genres} </span>
                </div>
                { i.node.video_title.length < 40 
                    ? <h6 className=" text-dark underline-not overflow-hidden" style={{height: 38}} >{i.node.translation_title}</h6>
                    : <h6 className=" text-dark underline-not" >{i.node.translation_title.slice(0,34)}...</h6>
                }
                { i.node.video_audio === "fr" &&
                    <span className="text-secondary">VF </span>
                } 
                { i.node.video_audio === "silent" &&
                    <span className="text-secondary">MUETSTFR </span>
                } 
                { i.node.video_subtitles === "fr" && i.node.video_audio !== "silent" &&
                    <span className="text-secondary">VOSTFR  </span>
                } 
            </div>
        </Link>
    )})
    return (
        <Layout>
            <GenericJumbotron title={title} url={url} description={description}/>
            <FullWidthAd client="ca-pub-3093835180445554" slot="7693037705"/>
            <div className=" d-flex flex-direction-row justify-content-center flex-wrap">
                {videos}
            </div>
            <p className="m-5">Listes similaires: {similar}</p>
            <div className="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-sm-1 mb-5">
                <Disqus config={{ url: url, identifier: title, title: title}} />
            </div>
        </Layout>
    )
}

export default VideoList
